import React, { Children, useEffect, useState } from 'react';

import { Container } from './styles';

interface IVideoBackgroundProps {
  slides: Array<string>;
  speed?: number;
}

const ImageSliderBackground: React.FC<IVideoBackgroundProps> = ({
  slides,
  speed = 5000

}) => {
  const [currentSlide, setCurrentSlide] = useState<string>(slides[0] || '');

  useEffect(() => {
    let x = 0;
    const length = slides.length - 1;

    setInterval(() => {
      x = x + 1;
      if (x > length) {
        x = 0;
      }

      setCurrentSlide(slides[x]);
    }, speed)


  }, [slides])

  return (<>
    <Container background={currentSlide} />
    <div style={{ background: 'rgba(0,0,0,0.7)', position: 'fixed', top: '0px', left: '0px', bottom: '0px', right: '0px', zIndex: -1, width: '100%', height: '100vh' }} />
  </>
  );
};

export default ImageSliderBackground;
