import { createGlobalStyle, keyframes } from 'styled-components';
import { defaultTheme } from '../config';
import themeDefault from './ThemeComponents';


const rotate = keyframes`
from {
  transform: rotate(0deg) ;
}
to{
  transform: rotate(360deg) ;
}
`

const fadeIn = keyframes`
0% {
  opacity: 0;
  filter: blur(10deg);
  transform: translateY(-20px);  
}
100% {
  opacity: 1;
  filter: blur(0deg);
  transform: translateY(0px);
}
`;

const pulse = keyframes`
0% {
  transform: scale(1)  rotate(47deg) translateY(-65%);
}
50% {
  transform: scale(1.1)  rotate(45deg) translateY(-55%);
}
100% {
  transform: scale(1)  rotate(47deg) translateY(-65%);
}
`;

export default createGlobalStyle`

@font-face {
    font-family: Montserrat;
    src: url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
}


@font-face {
    font-family: MontserratBold;
    src: url("/assets/fonts/Roboto-Bold.ttf") format("truetype");
}



*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  text-decoration: none;
}

html{
  width: 100%;
  min-height: 100vh !important;


}

.whiteModal { 
  p,span,h3, strong, h2, h1, h4, nav, aside {
    color: #333 !important;
  }
}

 

body {
 background: url('/apoio/fundo1.jpeg') no-repeat center;
background-size: cover ;
background-attachment: fixed;
 

>iframe {
  position: relative !important;
  display: none ;
}

@media print {
  
  background:#fff;

  color: #000 !important;
  p,h2,h3,h1{
    color: #000 !important;
  }

}

.breakPage{
@media print {
page-break-after: always;
}
}


.full-width {
  width: 100%;
  max-width: 100%;
}

.bandooBox {

  padding: 15px;
  background: #fff;
  border-radius: 10px;
  h2{
    font-size: 12px;
    padding: 0px 30px;
    margin-bottom: -50px;
    color: #777;
    

  }
}

.rocket{
 font-size:  82px;
 position: absolute;
  top: 47%; 
  right: -15px; 
  color: #9431ef !important;
  transform: translateY(-50%) rotate(45deg) ;
  animation:${pulse} 1s infinite ;
  z-index: 100;


}

.row-centered{
  width: 100%;
  display: flex ;
  justify-content: center;
  align-items: center;
}

.gap{
  gap: 15px;
}

.pad{
  padding: 15px !important;
}

.IconBall, .IconBallActive{
  padding: 10px;
  border-radius: 50%;
 
  display: flex ;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px; 
  background: #fff;
  color: #9431ef;
  cursor: pointer;

  svg{
    font-size: 24px;
  }

  transition: 0.5s all;

  &:hover{
    background: #9431ef;
  color: #fff;
  }
}

.IconBallActive{
  background: #9431ef;
  color: #fff;
}

.row-start{
  width: 100%;
display: flex ;
justify-content: flex-start;
align-items: center;
flex-direction: row;

@media (max-width: 750px) {
  flex-direction: column;
  }

} 

.row-to-column{
  width: 100%;
display: flex ;
justify-content: center;
align-items: center;
flex-direction: row;

@media (max-width: 750px) {
  flex-direction: column;
  }

} 
.row-to-column-start{
  width: 100%;
display: flex ;
justify-content: center;
align-items: center;
flex-direction: row;

@media (max-width: 750px) {
  flex-direction: column;
  justify-content: flex-start;
  }
} 
.gap-sm{
gap: 15px;
} 

.labelTag {
  padding: 3px;
  border: 1px solid rgba(0,100,150) ;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  color: rgba(0,100,150);
}

.shadowButtonSelect{
  background: #efefef ;
  border: 2px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
  border-radius: 10px;
  padding: 15px ;
  display: flex ;
  justify-content: center;
 
  width: 100%;
  transition: 0.5s all ;

  &:hover{
    background: #ddd;
  }
  
}

.glow{
  box-shadow: 2px 1px 10px 4px #ffcc80;
}

.yellowBallon{

position: absolute ;
top: 100px;
left: 30px;
width: 100px;
height: 100px;
background: #fcf214 ;
display: flex !important ;
align-items: center;
justify-content: center;
border-radius: 50%;
transform: rotate(-45deg);
color: #881d0b;
font-weight: bold;
text-align: center;
box-shadow: 2px 2px 10px rgba(0,0,0,0.7);

@media (max-width: 750px) {
  top: 70px;
left: 30px;
width: 70px;
height: 70px;
z-index: 100;
font-size: 11px;
  }

}



.tableRef{
  width: 100%;
  max-width: 800px;
  background: #fff ;
    border: 2px solid #ccc;
    margin: 10px auto;
    border-collapse: collapse;

    tr.highlight{
      background: rgb(12, 148, 144);
      color: #fff;
      th{
        color: #fff !important;
        text-align: left; 
      }
    }
    td,th {
      border: 1px solid #ddd;
      padding: 10px;
      color: #333;
    }
  }

.lineIcon {
      margin:5px;
      background: #fff;
       border: 1px solid #333;
       border-radius: 50%;
       height: 35px;
       width: 35px;
       padding: 5px;
       
       >svg{
         width: 25px !important;
         font-size: 25px;

       }
       display: flex;
       align-items: center;
       justify-content: center;
    }

.buttonBigGreen{

align-self: center;
  background-color: #1c9284;
  border-bottom-style: none;
  border-color: #1c9284;
  border-left-style: none;
  border-radius: 20px;
  border-right-style: none;
  border-top-style: none;
  border-width: 0px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  line-height: 1.42857;
  margin-bottom: 20px;
  margin-top: 0px;
  max-width: 388px;
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 20px;

}

.buttonBigGreenIcon{

align-self: center;
  background-color: #1c9284;
  border-bottom-style: none;
  border-color: #1c9284;
  border-left-style: none;
  border-radius: 10px;
  border-right-style: none;
  border-top-style: none;
  border-width: 0px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  line-height: 1.42857;
 
  margin-top: 0px;
  max-width: 388px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  padding-right: 10px;
  padding-left: 10px;
  
gap: 10px;
display: flex ;
align-items: center;
justify-content: center;
 
  >svg{
    font-size: 24px;
    min-width: 20px;
    min-height: 20px;
    color: #fff;
  }

}

.logocapa{
  width: 100%;
  @media (max-width: 750px) {
      max-width: 230px !important;
      width: 230px !important;
      margin: 0px auto;
  }
}

.fadeIn{
    animation : ${fadeIn} 1s forwards;
  }

.greenButton2{
    padding: 10px 20px;
    background: #fff;
    color: #9431ef;
    border: 0px;
    border-radius: 10px;
    margin: 10px 0px;
    transition: 0.4s all;
    font-weight: bold;

    display: flex !important ;
    gap: 5px  !important;
    justify-content: center  !important;
    align-items: center  !important;
    flex-direction: row  !important;

    &:hover{
      transform: scale(1.15) ;
    }
  }



  .greenButton2Mini{
    padding: 10px;
    background: #fff;
    color: #9431ef;
    border: 0px;
    border-radius: 10px;
    margin: 10px 0px;
    transition: 0.4s all;
    font-weight: bold;
    font-size: 14px;

    display: flex !important ;
    gap: 5px  !important;
    justify-content: center  !important;
    align-items: center  !important;
    flex-direction: row  !important;

    &:hover{
      transform: scale(1.15) ;
    }
  }

  .greenButton2Mini.active{
    
    background: #9431ef !important;
    color: #fff;
     
  }

  .row{
    display: flex ;
    flex-direction: row;
    align-items:center;
  }

  .row-start{
    display: flex ;
    flex-direction: row;
    justify-content: flex-start;
  }

  .gap-sm{
    gap: 15px;
  }

  .gap-xs{
    gap: 7.5px;
  }

  .outerBlock{
 
 padding:  15px;
 border: 1px solid #ddd;
 border-radius: 7.5px;


 .inputDiv{
  display: flex;
 align-items: center;
 justify-content: flex-start;
 border-radius: 5px;
 padding: 10px 15px;
 border: 1px solid #ddd;
background: #fff ;
color: #333;
 transition: 0.5s all;
 width: 100%;
 min-height: auto;
 flex-direction: column;

 input, select{
   padding: 5px 5px;
   &::placeholder {
      color: #777;
    }
 }

 >span{
   position: relative ;
   top: auto;
   left: auto;
   width: 100%;
   text-align: left;
 }

 }
 
  }

  .defaultBlock{
    display: flex;
 align-items: center;
 justify-content: flex-start;
 border-radius: 5px;
 padding: 10px 15px;
 border: 1px solid #ddd;
background: #fff ;
color: #333;
 transition: 0.5s all;
 width: 100%;
  }

  .borderBlock{
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    display: flex ;
    gap: 15px;
    border: 1px solid #333;
    padding: 5px 16px;
    border-radius: 5px;
  }

  .globalFooter{
    z-index: 100;
    background: rgb(148, 49, 239) ;

    display: flex ;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;

    >p{
      
    @media (max-width: 750px) {
      
      text-align: center;
      width: 100%;
  }
    }

div{
  flex-wrap: wrap;

  display: flex ;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 100%;
    

    p{
      color: #fff;
      transition: 0.5s all;
    }

    a{
 
      min-width: 150px  !important;
      color: #fff !important;
      background: none !important ;
      border: 0px;
      padding: 0px;
      margin-top: 0px;
      &:hover{
        p{
          transform: scale(1.1);
        }
      }
    }

    @media (max-width: 750px) {
      
      margin-bottom: 15px;
  }
  }
}

  .buttonColored {
    background:#9431ef !important ;
    color: #fff !important;
    
    p{
      color: #fff !important;
    }
  }

  .redButton{
    background:rgb(150,0,0) !important ;
    color: #fff !important;
    
    p{
      color: #fff !important;
    }
  }

.titleElement{
  font-size: 28px !important;
  text-transform: uppercase;
}

.p50.socialFooter .iconSet svg{
  
    min-width: 45px !important;
    font-size: 45px !important;
 
}
 
.searchButton{
    background: rgba(0,100,0);
    color: #fff;
    width: 50px;
    height: 50px;
    padding: 10px;
    margin: 5px;
    border: 0px;

    box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
    transition: 0.5s all;
    border-radius: 50%;

    >svg{
      width: 30px;
      font-size: 30px;
      color: #fff;
    }

    &:hover{
      background: rgba(0,150,0);
    }
  }

  .searchButtonMini{
    background: rgba(0,100,0);
    color: #fff;
    width: 30px;
    height: 30px;
    padding: 5px;
    margin: 5px;
    border: 0px;

    box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
    transition: 0.5s all;
    border-radius: 50%;

    >svg{
      width: 20px;
      font-size: 20px;
      color: #fff;
    }

    &:hover{
      background: rgba(0,150,0);
    }
  }
 


  background-position:  top;
  
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;


 
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0 !important;
  min-height: 100vh !important;
  min-height: 100% !important;
  position: relative;



}

.tooltip{
  background: #777 ;
  color: #333;
}

.spin {
  animation: ${rotate} 2s infinite ;
}

.table{
  width: 100%;
  border-collapse: collapse;
  td{
    border: 1px solid #ddd;
    padding: 10px;
    color: #333;

  }
}


.searchDivStart2{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items:center;
        max-width: 1600px;
        padding: 0px 15px;
        margin: 0px auto;

        >select, input {
          min-width: 300px;
          padding: 10px;
          border-radius: 15px;
          background: #fff ;
          border: 0px;
          margin:  10px;
        }

        button{
          padding: 10px;
          border-radius: 15px;
          background: #fff ;
          border: 0px;

          >svg{
            font-size: 18px;
          }
        }

        @media (max-width: 750px) {
    justify-content: center;
    flex-direction: column;
    select, input {
      width: 100%;

    }

    div{
      width: 100%;
    }
    
  }
      }

.slick-slider{
  width: 100%;

 
}

.slick-prev{
  background: none !important ;
  width: auto !important ;
  height: auto !important ;
  border-radius: 50%;
  z-index: 1;
  &:before{
    color: #f15a23;
    font-size: 40px;
    z-index:10;
    opacity: 1 ;
  }
}

.slick-next{
  background:  none !important ;
  width: auto !important ;
  height: auto !important ;
  border-radius: 50%;
  z-index: 1;
  &:before{
    color:  #f15a23;
    font-size: 40px;
    opacity: 1 ;
  }
}

.fixedWhatsapp{
  position: fixed;
    bottom: 15px;
    right: 0px;
    border-radius: 10px 0px 0px 10px;
    background: rgb(50,150,26);
    color: #fff !important;
    padding: 15px;
    border: 0px;
}

.CaptureLeads{
  min-width: 250px;
  background: rgb(241, 90, 35)  ;
  color: ${themeDefault.textComponent.title.color} ;
  padding: 10px 15px;
  border-radius: 5px;
  border: 0px;
}

.divSecoundaryBackground {
  background: ${themeDefault?.textComponent?.title.color} !important;
}

.shadow{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.padding{
  padding: 15px;
}

.margin{
  margin: 15px;
}

.shadowBox{
  display: flex;
    background: rgb(255, 255, 255) !important;
    box-shadow: rgb(7 13 28 / 25%) 0px 10px 100px;
    margin: 20px 0px;
    border-radius: 10px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#root{
  min-height: 100vh;
 


}

body, input, button{
  font-family: Helvetica,  sans-serif;
  font-size: 16px;
}

h1,h2,h3,h4,h5,h6, strong{
font-weight: bold;
font-family:  'Roboto',Helvetica,  sans-serif;
 
}

h2{

      font-size: 16px;
}

p, span{
  font-family:'Roboto',Helvetica,  sans-serif;
  
}

button{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  >svg {
    margin: 0 5px;
  }
}

.wave {

  width: 200%;
  margin-left: -20%;
  z-index:0;

  @media (max-width: 1250px) {
    width: 400%;
    margin-left: -50%;
  }

  @media (max-width: 750px) {
    width: 800%;
    margin-left: -50%;
  }



}


.computerOnly{
display: flex !important;
@media (max-width: 1200px) {
    display: none !important;
  }
}

.mobileOnly{
display: none !important;
@media (max-width: 1200px) {
    display: flex !important;
  }
}

.fixedBottom{
    z-index: 0;
    position: fixed;
    bottom: -30px;
    right: 0px;
    width: 45%;
}

div.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      max-width: 250px;
      padding: 20px;
      text-align: center;
      background: #fff !important;
      border: 2px solid rgb(0, 15, 64);
      color: rgb(0, 15, 64)  !important;
font-weight: bold;
      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;

      border-radius: 10px;
      box-shadow: 0px 5px 15px rgba(255,255,255,0.3);

      svg{
        cursor: pointer;
        transition: 0.4s all ;
        &:hover{
          transform: scale(1.1) ;
        }
      }
   
    }

button.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      max-width: 250px;
      padding: 15px;
      text-align: center;
      background: #fff !important;
      border: 2px solid rgb(0, 15, 64);
      color:  rgb(0, 15, 64)  !important;
font-weight: bold;
      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;

      border-radius: 10px;
      box-shadow: 0px 5px 15px rgba(255,255,255,0.3);
      &:hover {
        transform: translateY(-5px);
       
      }
    }


button.defaultButtonRed {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      background: red !important;
      color: #fff  !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
    
      }
    }

    .defaultButtonReverse {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 15px;
      text-align: center;
      flex-direction: column;
      display: flex ;
      justify-content: center;
      align-items: center;
 
      background: rgb(0, 15, 64)  !important;
      font-weight: bold;

      color: #fff !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;
    
      &:hover {
        transform: translateY(-10px);
        
    }

    .tip{
      padding: 5px;
     font-size: 14px;
      width:100%;
      text-align: left;
      font-weight: bold;
   
      color: #916f00 !important;
      display: flex ;
      justify-content: flex-start;
      align-items: center;

      >svg {
        animation: ${rotate} 5s infinite ;
      }
     
  
    }

    .rotateAnimation{
      animation: ${rotate} 5s infinite ;
    }

    .simpleButton {
         margin-top: 0px;
 
  
      padding: 5px;
      text-align: center;
      background: #fff !important;
      color: #333  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      >a{
        color: #333  !important;
        font-weight: bold;

      }

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }


    .defaultButtonReverseMini {
         margin-top: 0px;
      width: 100%;
      max-width: 200px;
      padding: 5px 10px;
      text-align: center;
      background: #f16621 !important;
      color: #fff  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      >a{
        color: #fff  !important;
        font-weight: bold;

      }

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    button.defaultButtonMini,  button.defaultButtonMiniRed,     button.defaultButtonMini2, button.buttonBlue {
         margin-top: 0px !important;
         padding: 5px !important;
      width:auto !important;
      min-width:auto !important;

      text-align: center !important;
      justify-content: center !important;
      background: rgb(0,15,64) !important;
      color: ${defaultTheme.defaultColor}  !important;

      display: flex  !important;
      justify-content: center !important;


      transition: background 0.5s;
      border: 0;
      border-radius: 10px;

      &:hover {
        background: ${defaultTheme.defaultBackground}  !important;
      }
    }

    button.defaultButtonMiniRed{
      background: rgb(0,150,50) !important;

      &:hover {
        background: rgb(0,200,50)  !important;
      }
    }
    button.defaultButtonMini2{
      padding: 10px 20px  !important ;
      margin-top: 25px ;
    }

    button.buttonBlue{
      background: rgb(0,100,150) !important;

      &:hover {
        background: rgb(0,120,180) !important;
      }
    }


    .closeButton{
      padding: 10px;
      border: 0;
      opacity: 0.8;
      cursor: pointer;
      background: #fff !important;
      color: #333  !important;
      border-radius: 10px  !important;
      padding: 10px;
      border-radius: 10px  !important;
      transition: 0.5s all;

      margin: 5px;
      &:hover{
        opacity: 1;
       
      }
    }


    a {
      text-decoration: none !important;
    }


    .lineIcon {
      margin:5px;
      background: #fff;
       border: 1px solid #333;
       border-radius: 50%;
       height: 35px;
       width: 35px;
       padding: 5px;
       
       >svg{
         width: 25px !important;
         font-size: 25px;

       }
       display: flex;
       align-items: center;
       justify-content: center;
    }

    .videoContainer{
      width: 100%;
      position: relative;
      height: auto;
   
    }

form {
    >div{
  
  background: #fff !important;
  color: #333  !important;
  border: 1px solid #ddd  !important;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.2)  !important;

  span{
    color: #333  !important;
  }

  input {
    color: #333  !important;

    &::placeholder {
    color: #333  !important;
  }
  }
}
}


`;
