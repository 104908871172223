import React from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
const ChartItem = ({ type, renderData, keyName }) => {
    const getOption = (typeChart, dataItems, keyChart) => {
        const data = dataItems?.reduce((prev, item) => {
            const index = prev.findIndex(i => i?.name === item?.[keyChart]);
            if (index >= 0) {
                prev[index].value += 1;
            } else {
                prev.push({ name: item?.[keyChart], value: 1 });
            }
            return prev;
        }, []);

        // Custom color palette
        const colorPalette = ['#5470C6', '#91CC75', '#EE6666', '#73C0DE', '#3BA272', '#FC8452', '#9A60B4', '#EA7CCC'];

        switch (typeChart) {
            case 'bar':
                return {
                    color: colorPalette,
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { type: 'shadow' },
                        formatter: '{b}: {c}'
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: data.map(item => item.name)
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        type: 'bar',
                        data: data.map(item => item.value),
                        barWidth: '60%',
                        itemStyle: {
                            borderRadius: 0,
                            color: '#9431ef'
                        }
                    }]
                };
            case 'pie':
                return {
                    color: colorPalette,
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b}: {c} ({d}%)'
                    },
                    series: [{
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        data: data,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }]
                };
            default:
                return {};
        }
    };

    return (
        <>
            {renderData?.length > 0 ? (
                <ReactEcharts
                    option={getOption(type, renderData, keyName)}
                    style={{ height: '350px', width: '100%' }}
                />
            ) : null}
        </>
    );
};

export default ChartItem;