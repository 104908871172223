import React, { useEffect, useState } from 'react';
import { addHours, getDate, getDay, getMonth } from "date-fns"
import { dateLanguageDate, monthNumber, simpleDateBR, simpleDateUS } from "../../../../../../utils/date";
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Form } from '@unform/web';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import Chart from '../../../../../../components/ChartModule';
import { FaCalendar, FaChartBar, FaChartPie, FaCheck, FaCreditCard, FaFileExcel, FaFilter, FaTicketAlt } from 'react-icons/fa';
import CheckboxInput from '../../../../../../components/Forms/CheckboxInputSearch';
import { useModal } from '../../../../../../hooks/Modal';
import { FiFilter } from 'react-icons/fi';
import { convertToXLSX } from '../../../../../../utils/download';
import { formatReal } from '../../../../../../utils/format';
interface IProps {
    sales?: Array<Record<string, any>>,
    salesProducts?: Array<Record<string, any>>,
    students?: Array<Record<string, any>>,
    products?: Array<Record<string, any>>,
}

interface IOption {
    id: string;
    label: string;
    value: string;
}


const convert = (list: Array<Record<string, any>>, group = false) => {

    const items: Array<Array<string>> = [];
    console.log(list)

    if (group) {
        items.push(['Nome', 'E-mail', 'Telefone', 'Estado', 'Cidade', "Total pago", "Data da venda", "Data da confirmação", 'Indicado por']);
        list?.map(item => {

            items.push([item?.name, item?.email, item?.phone, item?.state || '', item?.city || '', item?.total, item?.createdAt, item?.approvedAt, item?.seller_hash]);

        })
    }
    else {
        items.push(['Nome', 'E-mail', 'Telefone', 'Estado', 'Cidade', "Total pago", "Data da venda", "Data da confirmação", 'Indicado por']);
        list?.map(item => {

            items.push([item?.name, item?.email, item?.phone, item?.state || '', item?.city || '', item?.total, item?.createdAt, item?.approvedAt, item?.seller_hash]);

        })
    }


    convertToXLSX(items);

}

const VendasRealizadas: React.FC<IProps> = ({ sales, salesProducts = [], students, products = [] }) => {
    const { addModal, removeModal } = useModal();
    const [chartType, setChartType] = useState('bar');
    const [sortBy, setSortBy] = useState('no');
    const [type, setType] = useState('day');
    const [product, setProduct] = useState<Array<string>>(() => {
        const items: Array<string> = []
        products.map(item => {
            items.push(item?._id?.toString());
        })

        return items;


    });
    const [paymentStatus, setPaymentStatus] = useState<Array<string>>([]);
    const [paymentMethod, setPaymentMethod] = useState<Array<string>>([]);


    // Define the target time zone as GMT-3
    const targetTimeZone = 'America/Sao_Paulo'; // Example for GMT-3, adjust as necessary


    const sortByType = [
        { label: 'Data', value: 'brDate' },
        { label: 'Status de pagamento', value: 'payment_status_id' },
        { label: 'Forma de pagamento', value: 'payment_method_id' },
        { label: 'Produto', value: 'product' },
    ]


    const dayType = [
        { label: 'Por dia', value: 'day' },
        { label: 'Por hora', value: 'hour' },
        { label: 'Por mês', value: 'month' },
        { label: 'Por ano', value: 'year' },
    ]

    const payment_status_id = [
        { label: 'Pago', value: 'paid', id: 'paid' },
        { label: 'Aguardando pagamento', value: 'waiting', id: 'waiting' },
    ]

    const payment_method_id = [
        { label: 'Cartão de crédito', value: 'credit_card', id: 'credit_card' },
        { label: 'Boleto', value: 'boleto', id: 'boleto' },
        { label: 'PIX', value: 'pix', id: 'pix' },
        { label: 'Cortesia', value: 'cortesy', id: 'cortesy' },
    ]

    const currentDayType = dayType?.find(item => item?.value === type);

    const currentProduct = products?.find(item => item?._id?.toString() === product);





    const typesBR = {
        day: 'dd/MM/yyyy',
        hour: 'HH',
        year: 'yyyy',
        month: 'MM',
    }

    const typesUSA = {
        day: 'yyyy-MM-dd',
        hour: 'HH',
        year: 'yyyy',
        month: 'MM',
    }

    const salesProductsList = sales?.reduce((prev, item) => {

        const userId = item?.payer_id_response?._id?.toString();
        const student = students?.find(userInfo => userInfo?._id?.toString() === userId)



        if (paymentStatus && paymentStatus?.length >= 0 && paymentStatus?.[0]) {
            if (paymentStatus?.findIndex(status => status === item?.payment_status_id) < 0) {
                return prev;
            }
        }

        if (paymentMethod && paymentMethod?.length >= 0 && paymentMethod?.[0]) {
            if (paymentMethod?.findIndex(method => method === item?.payment_method_id) < 0) {
                return prev;
            }
        }

        const dateInTargetTimeZone = utcToZonedTime(item.createdAt, targetTimeZone);

        // Format date to dd/MM/yyyy for dateBr
        let brDate = format(dateInTargetTimeZone, typesBR[type]);

        if (sortBy === 'product') {
            brDate = item?.product_id_response?.title
        }

        if (sortBy === 'payment_status_id') {
            brDate = payment_status_id?.find(status => status?.value === item?.payment_status_id)?.label || '';
        }

        if (sortBy === 'payment_method_id') {
            brDate = payment_method_id?.find(method => method?.value === item?.payment_method_id)?.label || '';
        }

        if (sortBy === 'state') {
            brDate = student?.state || 'Não informado';
        }

        if (sortBy === 'city') {
            brDate = student?.city ? `${student?.city} (${student?.state})` : 'Não informado';
        }

        if (sortBy === 'company') {
            brDate = student?.company || 'Não informado';
        }

        if (sortBy === 'disability_type') {
            brDate = student?.disability_type ? student?.disability_type?.trim() : 'Não';
        }

        if (sortBy === 'know_about') {
            brDate = student?.know_about || 'Não informado';
        }

        if (sortBy === 'seller_hash') {
            brDate = item?.seller_hash || 'Sem indicação';
        }

        // Format date to yyyy-MM-dd for dateUS
        const date = format(dateInTargetTimeZone, typesUSA[type]);



        if (sortBy === 'yes') {
            if (prev.findIndex(i => i?.userID === student?._id?.toString()) >= 0) {
                return prev;
            }
        }
        prev.push({ total: formatReal(item?.total), createdAt: simpleDateBR(item?.createdAt), approvedAt: simpleDateBR(item?.approved_at), date: date, brDate, count: 1, userID: student?._id?.toString(), haveClientID: item?.client_id_response?._id ? 'Sim' : 'Não', product: item?.product_id_response?.title, name: student?.name, email: student?.email, phone: student?.phone, seller_hash: item?.seller_hash, company: student?.company, disability_type: student?.disability_type, know_about: student?.know_about, state: student?.state, city: `${student?.city} (${student?.state})` });
        return prev;

    }, []);



    const sortedList = salesProductsList?.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);



    return <>

        <div className=' full-width' style={{ background: '#eee', padding: '10px 16px', width: '100%' }}>



            <div className='row row-start full-width gap'>

                <div>
                    <div onClick={() => addModal({
                        theme: 'whiteModalMini',
                        key: 'payment_status_id',
                        title: '', content: <>
                            <Form initialData={{
                                payment_status_id: paymentStatus
                            }} onSubmit={(data) => { setPaymentStatus(data?.payment_status_id); removeModal('payment_status_id') }}>
                                <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><button type='submit' style={{ minWidth: '250px' }} className='greenButton2 buttonColored'>Salvar</button> </aside>

                                <CheckboxInput name='payment_status_id' options={payment_status_id} />
                                <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><button type='submit' style={{ minWidth: '250px' }} className='greenButton2 buttonColored'>Salvar</button> </aside>
                            </Form>
                        </>
                    })}><button className="greenButton2 buttonColored"><FiFilter /> Status de Pagamento {paymentStatus?.length > 0 ? <aside style={{ padding: '5px', minWidth: '20px', fontSize: '10px', minHeight: '20px', borderRadius: '50%', color: '#9431ef', background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{paymentStatus?.length || '0'}</aside> : <></>}</button></div>
                </div>
                <div>
                    <div onClick={() => addModal({
                        theme: 'whiteModalMini',
                        key: 'payment_method_id',
                        title: '', content: <>
                            <Form initialData={{
                                payment_method_id: paymentMethod
                            }} onSubmit={(data) => { setPaymentMethod(data?.payment_method_id); removeModal('payment_method_id') }}>
                                <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><button type='submit' style={{ minWidth: '250px' }} className='greenButton2 buttonColored'>Salvar</button> </aside>

                                <CheckboxInput name='payment_method_id' options={payment_method_id} />
                                <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><button type='submit' style={{ minWidth: '250px' }} className='greenButton2 buttonColored'>Salvar</button> </aside>
                            </Form>
                        </>
                    })}><button className="greenButton2 buttonColored"><FiFilter /> Forma de pagamento {paymentMethod?.length > 0 ? <aside style={{ padding: '5px', minWidth: '20px', fontSize: '10px', minHeight: '20px', borderRadius: '50%', color: '#9431ef', background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{paymentMethod?.length || '0'}</aside> : <></>}</button></div>

                </div>
            </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', width: '100%', gap: '10px', background: '#eee', padding: '10px 16px', }}>
            <div className='borderBlock'>
                <p style={{ padding: '5px 0px' }}>Agrupar Mailing por Titular?</p>
                <div style={{ width: 'auto', gap: '5px', display: 'flex', minWidth: 'auto' }}>
                    <div title='Sim' onClick={() => setSortBy('yes')} className={sortBy === 'yes' ? 'greenButton2Mini buttonColored shadow' : 'greenButton2Mini shadow'}>
                        Sim
                    </div>
                    <div title='Não' onClick={() => setSortBy('no')} className={sortBy === 'no' ? 'greenButton2Mini buttonColored shadow' : 'greenButton2Mini shadow'}>
                        Não
                    </div>

                </div>

            </div>


            <div style={{ color: '#333', display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}><p>Registros: {sortedList?.length}</p>
                <button onClick={() => convert(sortedList, sortBy === 'yes')} className='IconBall shadow'><FaFileExcel style={{ cursor: 'pointer' }} /></button></div>

        </div>
        <table className="tableRef" style={{ width: '100%', maxWidth: '100%' }}>
            <tr>

                <th>Titular</th><th>E-mail</th><th>Telefone</th><th>Estado</th><th>Município</th><th>Data da venda</th><th>Data da confirmação</th>

                <th>Total pago pelo comprador</th>
                <th>Indicado por</th></tr>
            {sortedList?.map(item => {
                return <tr key={`resumo-vendas-${item?._id?.toString()}`}>

                    <td>{item?.name}</td>
                    <td>{item?.email}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.state || ''}</td>
                    <td>{item?.city || ''}</td>
                    <td>{item?.createdAt}</td>
                    <td>{item?.approvedAt}</td>
                    <td>{item?.total}</td>
                    <td>{item?.seller_hash}</td>
                </tr>
            })}

        </table>


    </>


}

export default VendasRealizadas;