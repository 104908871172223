import styled, { keyframes } from 'styled-components';

const fadeFromRight = keyframes`
from {
  opacity: 0;
  transform: translateX(50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;
export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  
  padding: 15px;
  gap: 15px;
  background: #ddd;
  width: 100%;

  .ball {
    width: 50px ;
    height: 50px ;
    font-size: 14px;
    color: #9431ef !important;
    background: #fff ;
    border-radius: 50%;
font-weight: bold;
    display: flex ;
    align-items: center;
    justify-content: center;
    background: #eee ;
  }

  .positionLeft{
    position: absolute ;
    top: 50% ;
    left: 0px;
    transform: translateY(-50%) ;
    z-index:1000;
    border-radius: 0px 50% 50% 0px;
  }

  .positionRight{
    position: absolute ;
    top: 50% ;
    right: 0px;
    transform: translateY(-50%) ;
    border-radius: 50% 0px  0px 50% ;
  }

  >h2{
    color: #333;
    margin: 15px auto;
    padding: 10px;
    text-align: center;
    width: 100% ;
  }

  .list-template-quantity{
    width: 130px;
    border-left: 2px solid #fff;
    display: flex ;
   align-items: center;
   justify-content: center;
  

   >svg{
     margin: 5px ;
     min-width: 20px;
   }
   
  }

.template-title-box{
  width: 100% ;
  
  display: flex ;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  margin: 5px auto;
  margin-top: 15px;
  background: rgb(0,14,64);
  border: 1px solid #fff;
  font-size: 16px;
  color: #fff;
  border-radius: 10px;

  >div{
   padding: 10px;
  }

  p{
    font-size: 12px !important;
    color: #fff !important;
  }
}


  
 .list-template, .list-template-dark{
   width: 100% ;
  
   display: flex ;
   align-items: center;
   justify-content: space-between;
  max-width: 500px;
   margin: 5px auto;
   background: #ddd;

   border: 1px solid #333;
   border-radius: 10px;
   font-size: 16px;
   color: #333;
   >div{
    padding: 10px;
   }

   >td{
     padding: 10px ;
   }

   .list-template-quantity{
   
    border-left: 2px solid #333;
   
  }
 }

 .list-template-dark{
   background: #333 ;
   color: #fff;
   margin: 10px auto;

  
 }
`;

export const Header = styled.div`
  width: 100%;
  background: none;
  color: #333;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    max-width: 400px;
    form {
      display: flex;

      > input {
        border: 0;
        padding: 20px;
        background: #eee;
        color: #333;
      }
      > button {
        border: 0;
        padding: 20px;
        background: rgb(0, 100, 150);
        color: #fff;
      }
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;

  > table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    table-layout: auto;
    background: white;
    margin-top: 10px;

    tr {
      td,
      th {
        border: 1px solid #ddd;
        text-align: left;
        color: #333;
        padding: 5px;
      }
    }

    tr + tr {
      border-top: 1px solid #999;
    }
  }
`;
