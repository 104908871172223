import styled from 'styled-components';
import { backgrounds } from 'polished';

interface IContainer {
  background: string;
}

export const Container = styled.div<IContainer>`
 
 
  transition: background 1s;

  background: url(${props => props.background}) no-repeat center;
  background-size: cover;
  background-attachment: fixed;

 width: 100%;
 height: 100vh;
 top: 0px;
 left: 0px;
 position: fixed;
 z-index: -1;
`;
