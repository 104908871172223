import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useModal } from '../../../../../../hooks/Modal';
import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { FaMailBulk } from 'react-icons/fa';
import Whatsapp from '../../../../core/components/Whatsapp';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function UpdateModule() {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const { configuration } = useConfiguration();

  const CheckTickets = async (): Promise<void> => {

    const response = await api.get(`/sales-products`, { params: { limitItems: 100000, where: { project_id: configuration?.url } } });
    const students = await api.get(`/students`, { params: { limitItems: 100000 } });

    const items: Array<Record<string, any>> = [];

    response?.data?.rows?.map(item => {

      if (item?.payment_status_id === 'paid' && !item?.client_id && item?.total > 1) {

        const student = students?.data?.rows?.find(i => i?._id?.toString() === item?.payer_id)

        const index = items?.findIndex(i => i?.payer_id === item?.payer_id);

        if (index < 0) {
          items?.push({ ...item, name: student?.name, phone: student?.phone, email: student?.email, count: 1 })
        }
        else {
          items[index].count += 1;
        }




      }

    })

    const content = <table>

      <tbody>
        <tr>
          <td> </td>
          <td>Compra</td>
          <td>Nome</td>
          <td>Telefone</td>
          <td>E-mail</td>
          <td>Ingressos</td>
        </tr>
        {items?.map(itemValue => {
          return <tr>
            <td><Whatsapp message="" text={`Olá, ${itemValue.name}! Nós somos do Mentes Brilhantes. Identificamos que você tem ${itemValue?.count} ingresso(s) em seu nome que não foram atribuídos a nenhum participante. Acesse seus ingressos em https://ingressos.grupomentesbrilhantes.com.br/app/${configuration?.url}/login `} number={`55${itemValue.phone.toString().replace(/[^0-9]/g, '')}`} /> : <></> </td>
            <td>{itemValue?.sale_hash}</td>
            <td>{itemValue?.name}</td>
            <td>{itemValue?.phone}</td>
            <td>{itemValue?.email}</td>
            <td>{itemValue?.count}</td>
          </tr>
        })}

      </tbody>


    </table>;

    addModal({ title: 'Lista', content, theme: 'whiteModal' });



  };



  const moduleUpdate = (): JSX.Element => (
    <button className="lineIcon" title="Visualizar ingressos não atribuidos" type="button" onClick={() => CheckTickets()}>
      <FaMailBulk />
    </button>
  );

  return moduleUpdate();
}
