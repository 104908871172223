import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle } from '../../../../../../config';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function ContactModule({

  lineData,

}: ICreateModule): JSX.Element {




  const moduleUpdate = (data: Record<string, any>): JSX.Element => (
    data.phone ? <Whatsapp message="" text={`Olá, ${data.name}! Nós somos do Mentes Brilhantes. Identificamos que você iniciou seu cadastro, mas não finalizou a etapa de pagamento. Ocorreu algum problema? Como podemos lhe ajudar?`} number={`55${data.phone.toString().replace(/[^0-9]/g, '')}`} /> : <></>
  );

  return moduleUpdate(lineData);
}
