import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { IconType } from 'react-icons/lib';

import {
  Container,
  SecondaryContainer,
  ContainerWhite,
  GreyContainer,
  Capa,
  ProgramContainer,
  Sponsors,
  Photo,
} from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useModal } from '../../hooks/Modal';

import { usePages } from '../../hooks/Pages';
import { useLanguage } from '../../hooks/Language';
import SlideList from '../DashboardPages/features/SliderComponent';
import ProjectList from '../DashboardPages/features/ProjectList';
import EventList from '../DashboardPages/features/EventsList';
import api from '../../services/api';
import { hash, urlWebsite } from '../../config';
import { Button } from '../../components/Button/styles';
import SliderContainer from '../../components/SliderContainer';
import { FaBrain, FaSearch, FaStar } from 'react-icons/fa';
import Calendar from './modules/Calendar';
import SelectSimple from '../../components/Forms/SelectSimple';
import EventsListDisplay from '../DashboardPages/features/EventsListDisplay';
import PosterListDisplay from '../DashboardPages/features/PosterListDisplay';
import TestimonialListDisplay from '../DashboardPages/features/TestimonialDisplay';
import { ContactIcons, ContactIconsOrange } from '../../components/Menu';
import themeDefault from '../../styles/ThemeComponents';
import HomeSlider from '../HomeSlider';
import { loadFromJson } from '../../utils/loadFromJson';
import HomeApplication from '../../PagesApplication/HomeApplication';
import { useConfiguration } from '../../hooks/Configuration';

interface IMenu {
  title: string;
  target: string;
  targetID: string;
  fluid: number;
  external: number;
  status: number;
}

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Home2: React.FC = () => {


  const { translate } = useLanguage();

  const { user } = useAuth();
  const { configuration } = useConfiguration();
  const [search, setSearch] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const sendToEvents = () => {

    let link = currentCategory ? `/${currentCategory}` : `/eventos`;

    if (search) {
      link = `${link}?search=${encodeURIComponent(search)}`;
    }

    window.location.href = link;

  }


  if (configuration?.url) {

    return <HomeApplication />


  }

  return (<>
    <Container style={{ minHeight: '90vh' }}>
      <Capa style={{ marginBottom: '25px' }}>
        <div className='p50 homeTitle' style={{ alignItems: 'flex-start' }}>

          <p>Seja bem-vindo a<br />
            <strong style={{ color: '#fff' }}>Plataforma de Cursos e Eventos</strong> Grupo Mentes Brilhantes.</p>



        </div>
        <div className='p50  '>
          <div className='searchDiv'>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <input value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  sendToEvents();
                }
              }} className='homeSelect' name="search" placeholder='Pesquisar' />

              <button onClick={() => sendToEvents()} type="button" className='homeSelectReverse'><FaSearch /></button>
            </div>
          </div>
          {/*   <button className='defaultButtonReverse alignSelfRight' style={{ width: '100%', margin: '4px 3px', padding: '10px 15px', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginBottom: '25px' }}><FaStar style={{ minWidth: '25px' }} /> Atualize seu cadastro</button> */}

        </div>
      </Capa>

      <Capa  >

        <div style={{ width: '100%', margin: '0px', maxWidth: '650px' }}>

          <EventsListDisplay showLinks allowed={['highlight', 'next', 'prev', 'nextLives']} />


        </div>
        {/*  <div className='calendarDiv' >
          <h2 style={{ marginBottom: '15px' }}>{translate('AGENDA DE EVENTOS')}</h2>
          <Calendar />

            </div> */}

      </Capa>

      {/*  <div className='ilustrationDiv2' >
        <HomeSlider />

            </div> */}

    </Container>

  </>
  );
};
export default Home2;
