import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconBaseProps } from 'react-icons';

import { useField, FormHandles } from '@unform/core';
import { Container, Field } from './styles';
import { Error } from '../styles';
import format from '../../../../../../utils/format';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import api from '../../../../../../services/api';
import { FaTrash } from 'react-icons/fa';

interface IListInput {
  label: string;
  name: string;
}

interface IInputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  list: Array<IListInput>;
  defaultItem?: Array<Record<string, any>>;
  placeholder: string;
}

const JsonListProducts: React.FC<IInputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => { },
  placeholder,
  defaultItem,
  list,
  ...rest
}): JSX.Element => {
  const { configuration } = useConfiguration();
  const projectHash = configuration?.url;
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(defaultValue || defaultItem || []);

  const [products, setProducts] = useState<Array<Record<string, any>>>([]);

  const loadProducts = async () => {
    const response = await api.get('/products', {
      params: {
        where: { project_id: projectHash },
        limitItems: 10000
      }
    })

    if (response?.data?.rows?.length > 0) {
      setProducts(response?.data?.rows);
    }
  }

  useEffect(() => {

    if (projectHash) {

      loadProducts();


    }
  }, [projectHash])

  const addRow = ({ product }) => {
    const newValue = [...value];


    const newLine = {
      product: product._id.toString(),
      title: product.title,
      price: format(product.price)
    }


    newValue.push(newLine);

    setValue([...newValue]);
  };

  const removeItem = (data, index) => {
    const newValue: Array<Record<string, any>> = [];
    data.map((product, indexProduct) => {
      if (index === indexProduct) {

      }
      else {
        newValue.push(product)
      }

      setValue(newValue);
    })
  }

  const printLine = (name, index, list) => {
    const inputs: Array<JSX.Element> = [];

    Object.keys(list).map(key => {
      inputs.push(
        <Field>
          <p style={{ width: '100%', textAlign: 'left' }}>{list[key].label}</p>

          {list?.[key]?.type && list?.[key]?.type === 'select' && list?.[key]?.options ?
            <div>
              <select
                onChange={e => {
                  const newValue = [...value];

                  if (!newValue[index]) {
                    newValue[index] = {};
                  }
                  if (!newValue[index]) {
                    newValue[index][list[key].name] = '';
                  }

                  newValue[index][list[key].name] = e.target.value;
                  setValue([...newValue]);
                }}
                name={`${name}_${index}`}>
                <option value="" selected={!value?.[index]?.[list?.[key]?.name]}>Selecionar </option>
                {list?.[key]?.options && list?.[key]?.options.map(l => {
                  return <option value={l.value} selected={value?.[index]?.[list?.[key]?.name] === l.value}>{l.text} </option>
                })}
              </select>
            </div>
            :
            list?.[key]?.type === 'text' ? <p style={{ fontSize: '12px' }}>{value[index][list[key].name]}</p> :
              list?.[key]?.type === 'currency' ?

                <div style={{ position: 'relative', width: '100%', maxWidth: '150px' }}>

                  <input style={{ width: '100%', height: '100%' }}
                    placeholder={list[key].label}
                    defaultValue={
                      value && value[index] && value[index][list[key].name]
                        ? format(value[index][list[key].name])
                        : '0,00'
                    }
                    value={format(value[index][list[key].name])}

                    onChange={e => {
                      const newValue = [...value];

                      if (!newValue[index]) {
                        newValue[index] = {};
                      }
                      if (!newValue[index]) {
                        newValue[index][list[key].name] = '';
                      }

                      newValue[index][list[key].name] = e.target.value;
                      setValue([...newValue]);
                    }}
                    name={`${name}_${index}`}
                  />

                </div>
                :
                <div>
                  <input
                    placeholder={list[key].label}
                    defaultValue={
                      value && value[index] && value[index][list[key].name]
                        ? value[index][list[key].name]
                        : ''
                    }
                    onChange={e => {
                      const newValue = [...value];

                      if (!newValue[index]) {
                        newValue[index] = {};
                      }
                      if (!newValue[index]) {
                        newValue[index][list[key].name] = '';
                      }

                      newValue[index][list[key].name] = e.target.value;
                      setValue([...newValue]);
                    }}
                    name={`${name}_${index}`}
                  /></div>

          }
        </Field>,
      );
    });

    inputs.push(<div style={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }} onClick={() => removeItem(value, index)}><FaTrash style={{ minWidth: '25px' }} /></div>)
    return inputs;
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,
      getValue: (ref: any) => {

        const valueGlobal = JSON.parse(ref.value);

        console.log(valueGlobal);

        valueGlobal.map(v => {
          v.price = v?.price ? parseInt(v.price.replace(',', ''), 10) : 0;
        })

        console.log(valueGlobal);
        return valueGlobal;
      },
    });
  }, [fieldName, registerField]);

  return (

    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

      <div style={{ display: 'flex', flexDirection: 'column', width: '250px', maxWidth: '250px' }}>
        {products.map(product => {

          return <aside style={{ margin: '5px auto', width: '100%', borderRadius: '10px', padding: '10px', border: '1px solid #ddd', cursor: 'pointer' }} key={`product-list-${product._id.toString()}`} onClick={() => addRow({ product })}>
            <strong style={{ fontSize: '11px' }}>{product.title}</strong><br />
            <p style={{ fontSize: '10px' }}>R$ {format(product.price)}</p>
          </aside>

        })}
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '0px 15px' }}>
        <h2>{placeholder}</h2>
        <input
          ref={inputRef}
          style={{ display: 'none' }}
          name={name}
          value={JSON.stringify(value)}
        />

        {value.map((line, index) => (
          <Container>{printLine(name, index, list)}</Container>
        ))}
      </div>
    </div>
  );
};

export default JsonListProducts;
