import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';
import { FaObjectGroup, FaReceipt } from 'react-icons/fa';
import format from '../../../../utils/format';
import uploadSales from './modules/UploadContacts';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}


const ProductsAdmin: React.FC = () => {
  const endpoint = '/products-manager';
  const title = 'Produtos';
  const { projectHash, eventHash } = useConfiguration();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto' },
    event_id: { ref: 'event_id', column: 'event_id', label: 'Evento' },
    hash_link: { ref: 'hash_link', column: 'hash_link', label: 'Link' },
    group_show: { ref: 'group_show', column: 'group_show', label: 'Grupo de distribuição' },
    group: { ref: 'group', column: 'group', label: 'Grupo de controle' },
    title: { ref: 'title', column: 'title', label: 'Título' },
    price: { ref: 'price', column: 'price', label: 'Preço', mask: 'currency' },
    before_price: {
      ref: 'before_price',
      column: 'before_price',
      label: 'Preço Cheio',
      mask: 'currency',
    },
    limited_quantities: {
      ref: 'limited_quantities',
      column: 'limited_quantities',

      label: 'Vagas Limitadas?',

    },

    total_quantities: {
      ref: 'total_quantities',
      column: 'total_quantities',
      label: 'Total de Vagas',
    },

    available_quantities: {
      ref: 'available_quantities',
      column: 'available_quantities',
      label: 'Vagas Disponíveis',
    },

    sold_quantities: {
      ref: 'sold_quantities',
      column: 'sold_quantities',
      label: 'Ingressos Vendidos',
    },

    waiting_quantities: {
      ref: 'waiting_quantities',
      column: 'waiting_quantities',
      label: 'Ingressos Aguardando Pg.',
    },

    status_id: {
      ref: 'status_id',
      column: 'status_id',
      label: 'Status',
    },
    valid_id: {
      ref: 'valid_id',
      column: 'valid_id',

      label: 'Valido?',

    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };
  const formSchema = {
    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    hash_link: { model: 'input', type: 'text', pattern: 'url', name: 'hash_link', label: 'Link' },
    next_product: { model: 'input', type: 'text', name: 'next_product', label: 'Código do próximo lote' },
    next_product_date: { model: 'datePicker', type: 'text', name: 'next_product_date', label: 'Data de virada de lote' },

    limited_quantities: {
      model: 'selectSimple',
      name: 'limited_quantities',
      label: 'Tem Limite de vagas?',

      default: 'Não',

      options: [


        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },


      ]

    },
    total_quantities: {
      model: 'input',
      type: 'text',
      name: 'total_quantities',
      label: 'Total de Vagas',
      defaultValue: 0,
    },
    group_show: { model: 'input', type: 'text', name: 'group_show', label: 'Grupo de Distribuição' },
    group: { model: 'input', type: 'text', name: 'group', label: 'Grupo de controle' },
    description: { model: 'input', type: 'text', name: 'description', label: 'Descritivo' },
    price: {
      model: 'currencyInput',
      name: 'price',
      label: 'Preço',
      defaultValue: '0',
    },
    position: {
      model: 'selectSimple',
      name: 'position',
      label: 'Posição',

      defaultValue: '1',

      options: [


        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' },
        { label: '16', value: '16' },
        { label: '17', value: '17' },
        { label: '18', value: '18' },
        { label: '19', value: '19' },
        { label: '20', value: '20' },


      ]

    },
    is_subscription: {
      model: 'selectSimple',
      name: 'is_subscription',
      label: 'É uma inscrição?',

      defaultValue: 'yes',

      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    only_subscription: {
      model: 'selectSimple',
      name: 'only_subscription',
      label: 'Somente para inscritos?',

      defaultValue: 'no',

      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    is_extra: {
      model: 'selectSimple',
      name: 'is_extra',
      label: 'É um complemento de carrinho?',

      default: 'Não',

      options: [


        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },


      ]

    },
    any_price: {
      model: 'selectSimple',
      name: 'any_price',
      label: 'Preço adicionável (Doações)',

      defaultValue: 'no',

      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    status_id: {
      model: 'selectSimple',
      name: 'status_id',
      label: 'Ativo?',

      defaultValue: 'Sim',

      options: [


        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },


      ]

    },


    category_id: {
      model: 'selectSimple',
      name: 'category_id',
      label: 'Categoria',

      defaultValue: 'local_no_qrcode',

      options: [


        { label: 'Presencial', value: 'local' },
        { label: 'Presencial sem QRCode', value: 'local_no_qrcode' },
        { label: 'Online', value: 'online' },

      ]

    },
    type: {
      model: 'selectSimple',
      name: 'type',
      label: 'Pago ou Gratuíto?',

      defaultValue: 'paid',

      options: [


        { label: 'Pago', value: 'paid' },
        { label: 'Gratuíto', value: 'free' },


      ]

    },

    valid_id: {
      model: 'selectSimple',
      name: 'valid_id',
      label: 'Link Válido?',

      defaultValue: 'Sim',

      options: [


        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },


      ]

    },



    before_price: {
      model: 'currencyInput',
      name: 'before_price',
      label: 'Preço Cheio',
      defaultValue: '0',
    },


    courses: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong style={{ fontSize: '10px' }}>
            (
            {data.id})
            {' '}
            {data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong>
            (
            {data.id})
            {data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </>
      ),
      storageType: 'json',
      endpoint: `/courses`,
      name: 'courses',
      label: 'Cursos',
    },
  };

  const formSchemaUpdate = {

    logo: {
      model: 'uploadImage',
      type: 'upload',
      name: 'logo',
      label: 'logo',
    },
    email_header: {
      model: 'uploadImage',
      type: 'upload',
      name: 'email_header',
      label: 'Cabeçalho de Email',
    },
    email_footer: {
      model: 'uploadImage',
      type: 'upload',
      name: 'email_footer',
      label: 'Rodapé de Email',
    },
    email_full_image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'email_full_image',
      label: 'Email de imagem completa',
    },
    ...formSchema
  };


  const fields = {
    mail_instructions: { model: 'input', type: 'text', name: 'mail_instructions', label: 'Instruções adicionais de e-mail' },
    boleto_instructions: { model: 'input', type: 'text', name: 'boleto_instructions', label: 'Instruções dos campos adicionais (sai no Boleto) ' },
    fields: {
      model: 'jsonInputList',
      type: 'text',
      name: 'fields',
      label: 'Campos',
      show: 'title',
      list: [
        { label: 'Campo', name: 'field', type: 'input' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'input' },
        { label: 'Aplicar Cupom', name: 'apply_coupon', type: 'input' },
        {
          label: 'Mascara', name: 'mask', type: 'select', options: [

            { label: 'Nenhuma', text: 'Nenhuma', value: '' },
            { label: '0000', text: '0000', value: '1000' },
          ]
        },
        {
          label: 'Tipo', name: 'type', type: 'select', options: [
            { label: 'Estrelas', text: 'Estrelas', value: 'star' },
            { label: '1 a 10', text: '1 a 10', value: 'range' },
            { label: 'Texto', text: 'Texto', value: 'input' },
            { label: 'Lista de Textos', text: 'Lista de Textos', value: 'arrayInputIncluder' },
            { label: 'Área de Texto', text: 'Área de Texto', value: 'textarea' },
            { label: 'Seleção', text: 'Seleção', value: 'select' },
            { label: 'Seleção de números', text: 'Seleção de números', value: 'selectSequence' },
            { label: 'Imagem', text: 'Imagem', value: 'image' },
          ]
        },
        { label: 'Opções de seleção', name: 'options', type: 'arrayIncluder' },
        {
          label: 'Obrigatório?', name: 'mandatory', type: 'select', options: [
            { label: 'Sim', text: 'Sim', value: 'yes' },
            { label: 'Não', text: 'Não', value: 'no' },
          ]
        },
        { label: 'Limite', name: 'limit', type: 'input' },
        { label: 'Label', name: 'label', type: 'input' },
        { label: 'De', name: 'from', type: 'input' },
        { label: 'Até', name: 'to', type: 'input' },

      ]
    },



    allow_boleto: {
      model: 'selectSimple',
      name: 'allow_boleto',
      label: 'Liberar Boleto',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_pix: {
      model: 'selectSimple',
      name: 'allow_pix',
      label: 'Liberar PIX',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_cortesy: {
      model: 'selectSimple',
      name: 'allow_cortesy',
      label: 'Liberar Cortesia',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_credit_card: {
      model: 'selectSimple',
      name: 'allow_credit_card',
      label: 'Liberar Cartão de Credito',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    benefits_title: { model: 'input', type: 'text', name: 'benefits_title', label: 'Título do botão de benefícios' },
    benefits_description: { model: 'richText', type: 'text', name: 'benefits_description', label: 'Descritivo dos benefícios' },
  }


  const formSchemaUpdateGroupProducts = {
    group_products: {
      model: 'JsonInput',
      where: { project_id: projectHash },
      bodyRender: (data: Record<string, any>) => (
        <nav style={{ fontSize: '10px' }}>
          <strong style={{ fontSize: '10px' }}>
            (

            {data.title}
          </strong>
          <br />
          {format(data.price)}
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong style={{ fontSize: '10px' }}>
            (

            {data.title}
            <br />
            {format(data.price)}
          </strong>

        </>
      ),
      storageType: 'id',
      endpoint: `/products`,
      name: 'group_products',
      label: 'Produtos',
    }
  }


  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: fields,
          validationSchema: {},
          endpoint,
          Icon: FaReceipt
        }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdateGroupProducts,
          validationSchema: {},
          endpoint,
          Icon: FaObjectGroup
        }),
      (lineData: Record<string, any>) =>
        uploadSales({
          lineData,

        }),


    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default ProductsAdmin;
