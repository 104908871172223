import React, { useState } from 'react';
import { useLoading } from "../../../../../../hooks/LoadingHook";
import { useModal } from "../../../../../../hooks/Modal";
import { useLanguage } from "../../../../../../hooks/Language";
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useParams } from 'react-router-dom';
import sleep from "../../../../../../utils/sleep";
import { useSocket } from '../../../../../../hooks/Socket';
import { useAuth } from '../../../../../../hooks/Auth';
import { countries } from '../../../Login/countries';
import { validaCNPJ, validaCPF } from '../../../../../../utils/validate';

type IPropsStatusConfirmation = 'waiting_confirmation' | 'processing' | 'error' | 'success';

export default function usePaymentRequest() {

    const { projectHash, eventHash } = useParams<Record<string, any>>();
    const { translate, language } = useLanguage();
    const { removeLoading, addLoading } = useLoading();
    const { socket } = useSocket();
    const { addModal } = useModal();
    const { addToast } = useToast();
    const { getTrackingRecommendation } = useAuth();

    const [requestStatus, setRequestStatus] = useState<IPropsStatusConfirmation>('waiting_confirmation')




    /* payment with 3 steps 
    1. Apply payment request status - waiting confirmation
    2. Confirm payment request - processing
    3. Finish - Success or Error
    
    */

    const paymentCheckStatus = {

        success: ({ message, sale_hash }) => {

            addLoading({ title: 'Compra confirmada. Você será redirecionado.', key: 'loading' });
            return window.location.href = `/app/${projectHash}/payment/v4/success/${sale_hash}`;



        },
        error: ({ message, sale_hash }) => {

            addModal({ title: 'Ocorreu um problema ao gerenciar seu pedido', theme: 'whiteModalMini', content: <>{message}</> });

            return;

        }
    }


    const paymentRequest = async ({ paymentContext, moveToCart: moveToCartFunction }) => {

        let mainStatus = 'waiting';

        const limit = 300;

        removeLoading('loading');

        let { cart, name, email, document_number, phone, disability_id, country, payment_type, billing, company, company_document_number, recaptcha } = paymentContext;

        if (cart.length < 1) {
            addModal({ title: 'Selecione ao menos 1 ingresso', theme: 'whiteModalMini', content: <p></p> });
            return;
        }

        if (!name) {
            addModal({ title: 'Preencha o campo nome, nos dados pessoais', theme: 'whiteModalMini', content: <p></p> });
            return;
        }
        if (!email) {
            addModal({ title: 'Preencha o campo e-mail, nos dados pessoais', theme: 'whiteModalMini', content: <p></p> });
            return;
        }
        if (!document_number) {
            addModal({ title: 'Preencha o campo CPF, nos dados pessoais', theme: 'whiteModalMini', content: <p></p> });
            return;
        }
        if (!phone) {
            addModal({ title: 'Preencha o campo Telefone, nos dados pessoais', theme: 'whiteModalMini', content: <p></p> });
            return;
        }

        if (billing?.document_type === 'cnpj') {
            if (!company) {
                addModal({ title: 'Preencha o campo Nome da Empresa', theme: 'whiteModalMini', content: <p></p> });
                return;
            }
            if (!company_document_number) {
                addModal({ title: 'Preencha o campo CNPJ', theme: 'whiteModalMini', content: <p></p> });
                return;
            }

            if (!validaCNPJ(company_document_number)) {
                //    addModal({ title: 'Preencha um CNPJ válido', theme: 'whiteModalMini', content: <p></p> });
                //  return;
            }
        }

        if (!recaptcha) {
            addToast({ title: 'Confirme que você não é um robo', type: 'error' });
            return;
        }

        const pais = countries.findIndex(c => c.value === country);



        const userInfo = {
            name,
            email,
            document_number: document_number ? document_number?.toString().replace(/[^0-9]/g, '') : '',
            phone: phone ? phone?.toString().replace(/[^0-9]/g, '') : '',
            disability_id,
            country,
            extra_3: countries?.[pais]?.dialCode,
            extra_2: countries?.[pais]?.label
        }

        if (country === 'BR') {
            if (!validaCPF(userInfo?.document_number)) {
                addToast({ title: 'Preencha um CPF válido', type: 'error' });
                return;
            }
        }


        addLoading({ title: 'Enviando seu pedido', key: 'loading' })



        socket?.off('checkout-status-message');
        socket?.on('checkout-status-message', (data) => {

            const { payment_status_id, message, sale_hash, moveToCart } = data;
            paymentCheckStatus?.[payment_status_id]({ message, sale_hash });
            mainStatus = payment_status_id;
            if (moveToCart === 'yes') {
                moveToCartFunction();
            }

        })

        const seller_hash = getTrackingRecommendation();

        if (seller_hash) {
            paymentContext.seller_hash = seller_hash;
        }

        await api
            .post(`/v4/apply-payment`, {
                ...paymentContext,
                user: userInfo
            })
            .then(async response => {

                mainStatus = 'waiting_confirm';

                removeLoading('loading');
                addLoading({ title: 'Aguardando confirmação de disponibilidade', key: 'loading' });

                for (let i = 0; i < 300; i++) {

                    if (mainStatus === 'success' || mainStatus === 'error') {




                        break;
                    }

                    const checkCart = await api.get(`v3/get-my-sale-request/${response?.data?.sale_hash}`);

                    const { payment_status_id, message, sale_hash, moveToCart } = checkCart?.data;

                    if (payment_status_id === 'success' || payment_status_id === 'error') {

                        paymentCheckStatus?.[payment_status_id]({ message, sale_hash, moveToCart });
                        mainStatus = payment_status_id;

                        if (moveToCart === 'yes') {
                            moveToCartFunction();
                        }

                        removeLoading('loading');
                        break;
                    }
                    if (payment_status_id === 'processing') {


                        mainStatus = payment_status_id;
                        if (mainStatus !== payment_status_id) {
                            removeLoading('loading');
                            addLoading({ title: 'Seu pagamento está sendo processado', key: 'loading' })
                        }

                    }

                    if (i === limit) {
                        removeLoading('loading');
                        addToast({ title: 'Conexão interrompida', description: 'Entre em contato com o suporte.', type: 'error' })
                        break;
                    }


                    await sleep(1000); // aguarda por 1 segundo (1000 milissegundos)
                }

                removeLoading('loading');

            })
            .catch(err => {
                addModal({
                    title: translate('Erro'),
                    content: <>{translate(err?.response?.data?.message || err?.response?.data?.error || translate('Erro ao efetuar pagamento'))}</>,
                    theme: 'whiteModalMini'
                });
                removeLoading('loading')
                return false;
            });


    }

    return { paymentRequest };


}